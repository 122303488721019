import { darken } from 'color2k';
import { AppLoader } from '@/components/AppLoader';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { usePageTitle } from '@/hooks/usePageTitle';
import { Flex } from '@/ui';
import { isGQLErrorOfType } from '@/utils/errors';
import { CenteredContent } from '../../components/CenteredContent';
import { NotFound } from '../../components/NotFound';
import { QuickCreateContextProvider } from '../../contexts/QuickCreateContext';
import { useGetQuickEntriesPage } from '../../hooks/useGetQuickEntriesPage';
import type { PageExperienceSlug } from './config';
import { PageExperienceProvider } from './contexts/PageExperienceContext';
import { Details } from './Details';

type Props = {
  slug: PageExperienceSlug;
};

export default function PageExperiencePoC({ slug }: Props) {
  const { job, page, isLoading, error, reset } = useGetQuickEntriesPage(
    'platform',
    slug
  );

  usePageTitle(page?.title);

  return (
    <QuickCreateContextProvider job={job} page={page} onReset={reset}>
      <PageExperienceProvider slug={slug}>
        {({ config }) => (
          <Flex
            bgColor={config.theme.page.backgroundColor}
            direction="column"
            flex="1"
            pb="8"
            sx={{
              '--chakra-colors-brand-500': config.theme.colors.primary,
              '--chakra-colors-brand-600': darken(
                config.theme.colors.primary,
                0.1
              ),
              '--chakra-colors-brand-700': darken(
                config.theme.colors.primary,
                0.2
              ),
              a: {
                color: config.theme.colors.primary,
              },
            }}
          >
            <CenteredContent maxW={{ md: '900px' }}>
              {isLoading ? (
                <AppLoader />
              ) : isGQLErrorOfType(error, 'QuickEntriesPageNotFound') ? (
                <NotFound />
              ) : error ? (
                <QuickCreateErrorMessage error={error} />
              ) : job ? (
                <Details />
              ) : null}
            </CenteredContent>
          </Flex>
        )}
      </PageExperienceProvider>
    </QuickCreateContextProvider>
  );
}
