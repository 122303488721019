import { useState } from 'react';
import { Card, CardBody } from '@/pages/QuickCreate/components/Card';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { Flex, Text } from '@/ui';
import { EntryForm } from './components/EntryForm';
import { Footer } from './components/Footer';
import { Hero } from './components/Hero';
import { SubscribeMenu } from './components/SubscribeMenu';
import { usePageExperienceContext } from './contexts/PageExperienceContext';

export const Details = () => {
  const { page } = useQuickCreateContext();
  const { config, entries } = usePageExperienceContext();
  const [title] = useState(page?.title ?? '');

  return (
    <>
      <Card
        flexDir="column"
        mb="4"
        mt="8"
        shadow="0px 0px 30px 2px rgba(0, 0, 0, 0.05)"
      >
        <Hero title={title} />

        <CardBody flexDir="column" gap="8" p={{ base: '14px', md: '28px' }}>
          {page?.description && (
            <Text
              dangerouslySetInnerHTML={{ __html: page.description }}
              sx={{
                a: {
                  color: 'customgray.dark',
                  textDecoration: 'underline',
                },
              }}
            />
          )}

          {config.subscribe && <SubscribeMenu />}
        </CardBody>
      </Card>

      <Flex flexDir="column" gap="4">
        {entries?.map((entry) => (
          <Card
            flexDir="column"
            key={entry.id}
            p="0"
            shadow="0px 0px 30px 2px rgba(0, 0, 0, 0.05)"
          >
            <CardBody data-group pos="relative">
              <EntryForm entry={entry} />
            </CardBody>
          </Card>
        ))}
      </Flex>

      <Footer />
    </>
  );
};
