import { darken } from 'color2k';
import { Box, Link } from '@/ui';

export const Footer = () => {
  return (
    <>
      <Box
        fontSize="lg"
        fontWeight="bold"
        mt="8"
        mx="auto"
        px={{ base: 4, sm: 0 }}
        textAlign="center"
      >
        Use our{' '}
        <Link
          color={darken('#E7E3F5', 0.4)}
          colorScheme="dark"
          href="/magic"
          target="_blank"
          textDecor="underline"
        >
          AI magic wand 🪄
        </Link>{' '}
        to get content on your calendar today.
      </Box>

      <Box mt="3" mx="auto" textAlign="center">
        Powered by{' '}
        <Link href="/" target="_blank">
          Agenda Hero
        </Link>
      </Box>
    </>
  );
};
