import { Heading } from '@/pages/QuickCreate/components/Heading';
import { Box, Flex, Image, Link } from '@/ui';
import { usePageExperienceContext } from '../contexts/PageExperienceContext';

type Props = {
  title: string;
};

export const Hero = ({ title }: Props) => {
  const { config } = usePageExperienceContext();

  return (
    <Flex direction="column" pos="relative">
      <Box
        bgImage={config.header?.banner}
        bgPos="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        borderTopRadius="xl"
        height="165px"
        w="100%"
      />

      <Flex align="center" gap="5" mt="-20px" px={{ base: '14px', md: '28px' }}>
        <Link
          _active={{ transform: 'scale(0.98)' }}
          _hover={{ shadow: '0px 0px 4px 1px rgba(0, 0, 0, 0.1)' }}
          alignItems="center"
          bg="white"
          border="1px solid rgba(0, 0, 0, 0.15)"
          borderRadius="12px"
          boxSize={{ base: '80px', md: '120px' }}
          display="flex"
          flexShrink="0"
          href={config.header?.href}
          justifyContent="center"
          overflow="hidden"
          p="10px"
          target="_blank"
        >
          <Image src={config.header?.logo} />
        </Link>

        <Box pt={{ base: '30px', md: '20px' }}>
          <Heading
            borderBottom="1px solid rgba(0, 0, 0, 0.15)"
            fontSize={{ base: '18px', sm: '22px', md: '28px' }}
            fontWeight="medium"
            pb="14px"
          >
            {title}
          </Heading>
        </Box>
      </Flex>
    </Flex>
  );
};
