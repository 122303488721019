import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { DateTime } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/DateTime';
import { Description } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/Description';
import { Location } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/Location';
import { Title } from '@/pages/QuickCreate/components/EntryList/components/EntryCard/components/EntryForm/components/Title';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { Flex, type FlexProps } from '@/ui';
import { AddToCalendarMenu } from './AddToCalendarMenu';

type Props = FlexProps & {
  entry: DecoratedQuickCreateEntry;
};

const noop = () => null;

export const EntryForm = ({ entry, ...props }: Props) => {
  const isSmBreakpoint = useIsBreakpoint('sm');

  if (!entry) {
    return;
  }

  const showLocationField = !!entry.locationWithPlace?.name;

  return (
    <Flex direction="column" w="100%">
      <Flex {...props} direction={{ base: 'column', sm: 'row' }} gap="3">
        <Flex direction="column" flex="1" gap="2">
          <Title entry={entry} mb="2" readOnly onChange={noop} />
          <DateTime entry={entry} readOnly onChange={noop} />
          {showLocationField && (
            <Location entry={entry} readOnly onChange={noop} />
          )}
        </Flex>

        {!isSmBreakpoint && <AddToCalendarMenu entry={entry} />}
      </Flex>

      {entry.description && (
        <Description entry={entry} ml="-2" readOnly onChange={noop} />
      )}

      {isSmBreakpoint && <AddToCalendarMenu entry={entry} />}
    </Flex>
  );
};
